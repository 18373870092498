"use client";

import { ErrorFallback } from '@/app/components/ErrorFallback';
import {
  EventTypes,
  SLIs,
  useLogEvent,
} from "@/app/hooks/useLogEvent";
import { t } from "i18next";
import { ErrorBoundary } from "react-error-boundary";

export const ErrorBoundaryWrapper = ({ children }: { children: React.ReactNode; }) => {
  const logError = useLogEvent(EventTypes.ERROR);
  const logSli = useLogEvent(EventTypes.SLI);
  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          errorTitle={t('error-fallback-heading')}
          errorMessage={t('error-fallback-copy-body')}
        />
      }
      onError={(error, _info) => {
        logError(error);
        logSli(SLIs.ERROR_BOUNDARY_SEEN, 1);
      }}
    >
      <main
        className="flex-1  bg-backgroundGray bg-gradient-to-b from-gradientStart to-gradientEnd z-[1] [body.menuActive_&]:overflow-hidden max-laptop:[body.menuActive_&]:hidden laptop:min-h-[100vh]"
        aria-labelledby="welcomeMessage"
      >
        {children}
      </main>
    </ErrorBoundary>
  );
}

