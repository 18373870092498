'use client';

import { useTranslation } from '@/app/i18n/client';
import { PhoneIcon } from '../PhoneIcon';
import { usePartner } from '@/lib/partners/usePartner/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';

export const TechnicalIssuesHelp = () => {
  const { t } = useTranslation();
  const partner = usePartner();
  const { contactPhoneNumber } = getPartnerSettings(partner);

  return (
    <div className="w-full bg-core-1 drop-shadow-ds1 flex flex-col rounded-2xl pl-8 text-sm lmobile:text-base">
      <div className="mb-5 mt-10 max-w-[510px] w-full pr-4">
        <div className="font-medium mb-5">
          {t('technical-issues-help-copy-heading')}
        </div>
        <div className="font-light text-core-7">
          {t('technical-issues-help-copy-body')}
        </div>
      </div>
      <div className="min-w-[210px] mt-5 mb-10 flex flex-row items-center mr-4">
        <div className="text-success-1 drop-shadow-insetDs1 bg-core-1  flex justify-center items-center border border-core-3 rounded-full w-10 h-10 mr-4">
          <PhoneIcon color="success-1" />
        </div>
        <div className="flex flex-col  justify-center">
          <div className="font-light text-core-7">
            {t('technical-issues-help-contact-us')}
          </div>
          <div className="font-medium">
            {t('contact-us-telephone-number').replace(
              '{contactPhoneNumber}', 
              contactPhoneNumber!
            )}</div>
        </div>
      </div>
    </div>
  );
};
