'use client';

// component needs to be client side because a button will be implemented to be able to reset the error boundary. WIP
import { TechnicalIssuesHelp } from '@/app/[lang]/[partner]/(Journey)/components/TechnicalssuesHelp';

export interface ErrorFallbackProps {
  errorTitle: string;
  errorMessage: string;
  content?: React.ReactNode;
}

export const ErrorFallback = (props: ErrorFallbackProps) => {
  const { errorTitle, errorMessage, content } = props;

  return (
    <div
      className="mt-20 ml-10 mb-6 mr-5 flex tablet:flex-row flex-col justify-center flex-1"
      role="alert"
    >
      <div className="mb-10 max-w-md text-left flex flex-col">
        <h2 className="mb-6 text-4xl">{errorTitle}</h2>
        <p className="font-light text-core-7 mb-6">{errorMessage}</p>
        {content}
      </div>
      <div className="max-w-md tablet:ml-16 tablet:mr-16 tablet:min-w-[17.5rem]">
        <TechnicalIssuesHelp />
      </div>
    </div>
  );
};
