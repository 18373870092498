const PhoneIcon = ({ color }: { color: string }): React.ReactNode => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="phone-line">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.818 14.634C12.1695 13.6957 10.8043 12.3305 9.866 10.682L11.104 9.798C11.516 9.50378 11.6431 8.94807 11.4 8.504C10.6271 7.08968 10.1622 5.52779 10.036 3.921C9.99479 3.40093 9.5607 2.99993 9.039 3H4.577C4.06413 2.99989 3.63431 3.3878 3.582 3.898C3.527 4.428 3.5 4.962 3.5 5.5C3.5 14.06 10.44 21 19 21C19.538 21 20.072 20.973 20.602 20.918C21.1122 20.8657 21.5001 20.4359 21.5 19.923V15.461C21.5001 14.9393 21.0991 14.5052 20.579 14.464C18.9722 14.3378 17.4103 13.8729 15.996 13.1C15.5519 12.8569 14.9962 12.984 14.702 13.396L13.818 14.634ZM9.243 8.668L7.343 10.025C7.55528 10.5713 7.80591 11.102 8.093 11.613L8.126 11.671C9.24228 13.6324 10.8666 15.2567 12.828 16.373L12.886 16.406C13.397 16.6931 13.9277 16.9437 14.474 17.156L15.831 15.256C16.9949 15.7952 18.2302 16.1646 19.499 16.353V18.99C19.333 18.997 19.166 19 18.999 19C11.543 19 5.499 12.956 5.5 5.5C5.5 5.333 5.503 5.166 5.509 5H8.146C8.33435 6.26884 8.70378 7.50409 9.243 8.668Z"
          className="fill-current"
        />
      </g>
    </svg>
  );
};

export { PhoneIcon };
