import { InitOptions } from 'i18next';

export function getOptions(partner: string, lng = 'en_GB') {
  const namespace = [
    partner,
    'legalOverrides',
    'default',
    'cookieBanner',
    'businessType',
    'industry',
    'intendedUseOfFunds',
    'ownershipStatus',
    'ordinals',
    'codatLink',
    'americanStates',
    'canadianProvinces',
    'prepop/formFields',
    'prepop/footer',
    'prepop/ordinals',
    'prepop/ownershipStatus',
    'prepop/businessDetails',
    'prepop/text',
    'prepop/yourDetails',
    'prepop/yourQuote',
    'prepop/yourFlexiQuote',
    'prepop/review',
    'prepop/reviewTerms',
    'prepop/explainerSection',
    'prepop/bankingDetails',
    'prepop/signContracts',
    'prepop/shareholderDetails',
    'prepop/caseStudy',
    'prepop/editDetails',
    'prepop/complete',
    'prepop/flexiComplete',
    'prepop/errors',
    'dashboard',
    'help-center',
    'paymentHistory',
    'user-details',
    'common',
    'error',
    'renewalStart',
    'renewalJourney',
    'renewalConfirm',
    'renewalComplete',
  ];

  return {
    fallbackLng: 'en_GB',
    fallbackNS: [...namespace],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    lng,
    defaultNS: partner,
    ns: [...namespace],
  } as InitOptions<any>;
}
